import { buildMapToField, handleQueryResolve } from '../utils'

export default function () {
  return this.query(`
    SELECT "DBA"."Prescription"."RxNumber",
    "DBA"."Drug"."GenericName",
    "DBA"."Drug"."Id" as MixtureId,
    "DBA"."Patient"."Id",
    "DBA"."Patient"."LastName",
    "DBA"."Patient"."FirstName",
    "DBA"."Prescription"."FillDate" as RxDate
    FROM ( "DBA"."Prescription" JOIN "DBA"."Drug" ON "DBA"."Prescription"."DrugId" = "DBA"."Drug"."Id" ) JOIN "DBA"."Patient" ON "DBA"."Prescription"."PatientId" = "DBA"."Patient"."Id"
    WHERE (Prescription.Status = 'COM' OR Prescription.Status = 'AMD') AND DBA.Drug.MixtureFlag = 1 AND FillDate < DATEADD( week, 0, GetDate() ) AND FillDate > DATEADD( year,  -1, GetDate() ) 
        `).then(handleQueryResolve)
    .then(records => records.map(row => buildMapToField(row)))
}
